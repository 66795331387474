import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {User} from "../../types/types";

type UserDetailsState = {
    user: User | null;
};

const initialState: UserDetailsState = { user: null };

const userDetailsSlice = createSlice({
    name: "userDetails",
    initialState,
    reducers: {
        setUser(state: UserDetailsState, action: PayloadAction<User>) {
            state.user = action.payload;
        },
        logout(state: UserDetailsState) {
            state.user = null;
        }
    },
});

export const selectLoggedInUser = (state: UserDetailsState) => state.user;

export default userDetailsSlice;