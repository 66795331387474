import React from "react";
import {Route, BrowserRouter, Routes} from "react-router-dom";
import store, { persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import ProtectedRoute from "./routes/ProtectedRoute";
import Login from "./pages/Login";
import Home from "./pages/Home";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { appTheme } from "./themes/theme";
import ForgottenPasswordEmail from "./pages/forgotten-password/ForgottenPasswordEmail";
import ForgottenPasswordEmailSent from "./pages/forgotten-password/ForgottenPasswordEmailSent";
import ForgottenPasswordChangePassword from "./pages/forgotten-password/ForgottenPasswordChangePassword";
import ForgottenPasswordComplete from "./pages/forgotten-password/ForgottenPasswordComplete";
import SessionExpired from "./pages/SessionExpired";
import MyAccount from "./pages/MyAccount";
import Budget from "./pages/Budget";
import InsurerProviders from "./pages/InsurerProviders";
import Services from "./pages/Services";

export default function App() {
  return (
      <>
      <CssBaseline />
        <ThemeProvider theme={appTheme}>
              <Provider store={store}>
                <PersistGate persistor={persistor} loading={null}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/forgottenPasswordEmail" element={<ForgottenPasswordEmail />} />
                            <Route path="/forgottenPasswordEmailSent" element={<ForgottenPasswordEmailSent />} />
                            <Route path="/forgottenPasswordChangePassword/:resetToken" element={<ForgottenPasswordChangePassword />} />
                            <Route path="/forgottenPasswordComplete" element={<ForgottenPasswordComplete />} />
                            <Route path="/sessionExpired" element={<SessionExpired />} />
                            <Route
                                path="/"
                                element={
                                    <ProtectedRoute>
                                        <Home />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/budget"
                                element={
                                    <ProtectedRoute>
                                        <Budget />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/providers"
                                element={
                                    <ProtectedRoute>
                                        <InsurerProviders />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/myAccount"
                                element={
                                    <ProtectedRoute>
                                        <MyAccount />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/services"
                                element={
                                    <ProtectedRoute>
                                        <Services />
                                    </ProtectedRoute>
                                }
                            />
                        </Routes>
                    </BrowserRouter>
                </PersistGate>
              </Provider>
          </ThemeProvider>
          </>
  );
}