import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material";


import Header from "../components/Header";
import NavigationTabs from "../components/NavigationTabs";
import axios from "axios";
import {Provider} from "../types/types";
import { useAppSelector } from "../store/hooks";
import employer from "../store/slices/employer";

function not(a: readonly Provider[], b: readonly Provider[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly Provider[], b: readonly Provider[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const InsurerProviders = () => {
    const dispatch = useDispatch();

    const loggedInUser = useAppSelector(state => state.userDetails.user);
    const accessToken = useAppSelector(state => state.auth.token);
    const loggedInEmployer = useAppSelector(state => state.employer.loggedInEmployer);

    const [checked, setChecked] = useState<Provider[]>([]);
    const [left, setLeft] = useState<Provider[]>(new Array<Provider>());
    const [right, setRight] = useState<Provider[]>(new Array<Provider>());
    const [updateSelectedProviders, setUpdateSelectedProviders] = useState(false);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);


    useEffect(() => {
        if (loggedInUser && loggedInEmployer) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            axios
                .get(`${process.env.REACT_APP_SERVER_API_URL}/providers?onboarded=true`, {headers: headers})

                .then((res) => {
                    const allProviders = res.data;
                    axios
                        .get(`${process.env.REACT_APP_SERVER_API_URL}/employers/` + loggedInEmployer.id + `/providers`, {headers: headers})
                        .then((res) => {
                            const selectedProviders = res.data;
                            dispatch(employer.actions.setSelectedProviders(selectedProviders));
                            setRight(selectedProviders);
                            let availableProviders = new Array();
                            for (let p of allProviders) {
                                let providerAlreadySelected = false;
                                for (let sp of selectedProviders) {
                                    if (sp.id === p.id) {
                                        providerAlreadySelected = true;
                                    }
                                }
                                if (!providerAlreadySelected) {
                                    availableProviders.push(p)
                                }
                            }
                            setLeft(availableProviders);
                        })
                        .catch((err) => {

                        });
                })
                .catch((err) => {
                });
        }
    }, [loggedInUser, accessToken, dispatch])

    useEffect(() => {
        if (loggedInUser && loggedInEmployer && updateSelectedProviders) {
            let selectedProvidersIds = new Array<string>();
            for (let p of right) {
                selectedProvidersIds.push(p.id);
            }
            const body = {
                selectedProvidersIds: selectedProvidersIds
            };
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            axios
                .patch(`${process.env.REACT_APP_SERVER_API_URL}/employers/` + loggedInEmployer.id, body, {headers: headers})
                .then((res) => {
                    dispatch(employer.actions.setLoggedInEmployer(res.data));
                    toast.success('Your changes have been successfully processed.');
                    setUpdateSelectedProviders(false);
                })
                .catch((err) => {
                    setUpdateSelectedProviders(false);
                    toast.error("There has been a problem saving your changes.");
                });
        }

    }, [loggedInUser, accessToken, dispatch, right]);

    const handleToggle = (value: Provider) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        setUpdateSelectedProviders(true);

    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
        setUpdateSelectedProviders(true);

    };


    const customList = (items: readonly Provider[]) => (
        <List component="div" role="list">
            {items.map((p: Provider) => {
                const labelId = `transfer-list-item-${p.id}-label`;

                return (
                    <div key={p.id}>
                        <ListItem
                            alignItems="flex-start"
                            role="listitem"
                            button
                            onClick={handleToggle(p)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(p) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        "aria-labelledby": labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemAvatar>
                                <img
                                    style={{ width: "100px", paddingRight: "1rem" }}
                                    alt={p.name}
                                    src={p.image}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                id={labelId}
                                primary={p.name}
                                primaryTypographyProps={{ variant: "h6" }}
                                secondary={
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Typography component={"span"} variant="body2" gutterBottom>
                                            {p.discount > 0
                                                ? "| " + p.discount + "% on all products"
                                                : ""}
                                        </Typography>
                                        <Typography
                                            component={"span"}
                                            sx={{ color: "black" }}
                                            variant="body2"
                                            gutterBottom
                                        >
                                            {p.shortDescription}
                                        </Typography>
                                        <Typography component={"span"} variant="body2">
                                            <a
                                                target="_blank"
                                                href={p.url}
                                                className="underline"
                                                rel="noreferrer"
                                            >
                                                Visit website
                                            </a>
                                        </Typography>
                                    </Box>
                                }
                            />
                        </ListItem>
                        <Divider
                            sx={{ margin: "0.5rem 0 0.5rem 0" }}
                            variant="fullWidth"
                            component="li"
                        />
                    </div>
                );
            })}
        </List>
    );


    return (
        <Box sx={{background:"#F5F5F5"}}>
            <Header />
            <NavigationTabs selectedTab={"2"} />
            <Box sx={{paddingLeft: {
                    xs:"16px",
                    sm:"50px"
                }, paddingRight: {
                    xs: "16px",
                    sm: "50px"
                }, width: "100%", height:"100%", display: "flex", flexDirection: "row"}} color="primary">

                <div className="bg-white rounded-lg border-grey shadow-md py-4 px-4 sm:py-8 sm:px-8 w-full">
                    <Box
                        sx={{
                            paddingLeft: {
                                xs: "16px",
                                sm: "50px",
                            },
                            paddingRight: {
                                xs: "16px",
                                sm: "50px",
                            },
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "row",
                        }}
                        color="primary"
                    >
                        <div className="w-full sm:w-1/2 mr-5 bg-white rounded-lg border-grey shadow-md sm:py-8 sm:px-8 py-4 px-4 h-full">
                            <Typography
                                fontWeight="bold"
                                mb={2}
                                color="secondary"
                                variant="h5"
                            >
                                Available health providers
                            </Typography>
                            {left.length > 0 ? (
                                customList(left)
                            ) : (
                                <Typography variant="body1">
                                    You have selected all available providers, which means your
                                    employees will benefit from everything that HealthKey has to
                                    offer. Once we onboard a new health provider, we will let you
                                    know.
                                </Typography>
                            )}
                        </div>
                        <Box sx={{ marginTop: "10rem" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                fullWidth
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="move selected right"
                            >
                                <ArrowRightOutlined sx={{ color: "white" }} />
                            </Button>
                            <Button
                                sx={{ marginTop: "1rem" }}
                                variant="contained"
                                size="small"
                                fullWidth
                                color="primary"
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected left"
                            >
                                <ArrowLeftOutlined sx={{ color: "white" }} />
                            </Button>
                        </Box>
                        <div className=" w-full sm:w-1/2  ml-5 bg-white rounded-lg border-grey shadow-md sm:py-8 sm:px-8 py-4 px-4 h-full">
                            <Typography
                                fontWeight="bold"
                                mb={2}
                                color="secondary"
                                variant="h5"
                            >
                                Published health providers
                            </Typography>
                            {right.length > 0 ? (
                                customList(right)
                            ) : (
                                <Typography variant="body1">
                                    You haven&apos;t selected any providers. Without a few
                                    providers published, your employees won&apos;t have any
                                    choices.
                                </Typography>
                            )}
                        </div>
                    </Box>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />
                </div>
            </Box>
        </Box>
    );
};

export default InsurerProviders;
