import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import NavigationTabs from "../components/NavigationTabs";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../store/hooks";
import {useFormik} from "formik";

import * as Yup from "yup";
import axios from "axios";
import {
    Backdrop,
    Box,
    Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, TableSortLabel, TextField, Tooltip,
    Typography
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import {useNavigate} from "react-router-dom";
import authSlice from "../store/slices/auth";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../components/Footer";

import {DeleteOutlined, SavingsOutlined, SearchOutlined} from "@mui/icons-material";
import { CSVLink, CSVDownload } from 'react-csv';
import {UserForDeletion, UserForInsurer} from "../types/types";
import insurer from "../store/slices/insurer";
import employer from "../store/slices/employer";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof UserForInsurer;
    label: string;
    numeric: boolean;
}

const Home = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loggedInUser = useAppSelector(state => state.userDetails.user);
    const accessToken = useAppSelector(state => state.auth.token);
    const loggedInInsurer = useAppSelector(state => state.insurer.loggedInInsurer);
    const usersForInsurer = useAppSelector(state => state.insurer.users);
    const [selectedUser, setSelectedUser] = useState<UserForInsurer | undefined>(undefined);
    const [selectedUserIndex, setSelectedUserIndex] = useState(-1);
    const [topUpModalIsOpen, setTopUpModalIsOpen] = useState(false);
    const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState<UserForDeletion | undefined>(
        undefined,
    );
    const [userIdToDelete, setUserIdToDelete] = useState("");

    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof UserForInsurer>('name');
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState<UserForInsurer[]>(new Array<UserForInsurer>());
    const [searchQuery, setSearchQuery] = useState("");
    const [csvData, setCsvData] = useState(new Array<any>());


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof UserForInsurer,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDeleteAccountDialogClose = () => {
        setDeleteAccountDialogOpen(false);
        setUserToDelete(undefined);
        setSelectedUserIndex(-1);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    const headCells: readonly HeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Employee name',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            id: 'insurancePolicyNumber',
            numeric: false,
            disablePadding: false,
            label: 'Policy number',
        },
        {
            id: 'balance',
            numeric: true,
            disablePadding: false,
            label: 'HK balance',
        },
        {
            id: 'insurerTopUp',
            numeric: true,
            disablePadding: false,
            label: 'Your top-up',
        }
    ];


    useEffect(() => {
        if (loggedInUser) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            axios
                .get(`${process.env.REACT_APP_SERVER_API_URL}/users/` + loggedInUser.id + '/insurer', {headers: headers})
                .then((res) => {
                    dispatch(insurer.actions.setLoggedInInsurer(res.data));
                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });

        }
    }, [loggedInUser, accessToken, dispatch])

    useEffect(() => {
        if (loggedInUser) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            axios
                .get(`${process.env.REACT_APP_SERVER_API_URL}/users/` + loggedInUser.id + '/employer', {headers: headers})
                .then((res) => {
                    dispatch(employer.actions.setLoggedInEmployer(res.data));
                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });

        }
    }, [loggedInUser, accessToken, dispatch])


    useEffect(() => {
        if (usersForInsurer) {
            if (searchQuery === "") {
                setRows(usersForInsurer);
                setExportCSVData(usersForInsurer);
            } else {
                const filtered = usersForInsurer.filter((u) => {
                    return (
                        u.email.includes(searchQuery.toLowerCase()) || u.name.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                });
                setRows(filtered);
                setExportCSVData(filtered);
            }
        }
    }, [searchQuery, usersForInsurer]);

    const setExportCSVData = (listOfUsers : UserForInsurer[]) => {
        let data = new Array<any>();
        const headers = ["Name", "Email", "HK budget", "Your topup"];
        data.push(headers);
        for (let user of listOfUsers) {
            data.push([user.name, user.email, user.balance, user.insurerTopUp]);
        }
        setCsvData(data);
    }

    useEffect(() => {
        readUsersForInsurer();
    }, [loggedInUser, accessToken, dispatch, loggedInInsurer])

    const readUsersForInsurer = () => {
        if (loggedInInsurer && loggedInUser) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }

            axios
                .get(`${process.env.REACT_APP_SERVER_API_URL}/insurers/` + loggedInInsurer.id + '/users', {headers: headers})
                .then((res) => {
                    dispatch(insurer.actions.setUsers(res.data));
                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });
        }
    }

    const visibleTextfield = {
        "& label": {
            color: '#6C6363'
        },
        '& label.Mui-focused': {
            color: '#6C6363',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6C6363',
        },
        "& .MuiInputBase-root": {
            color: '#6C6363'
        }
    };

    const topUpBudgetForUser = (amount : number) => {
        if (loggedInUser && selectedUser) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            const body = {
                "amount": amount,
                "currency": "GBP",
                "sourceInsurerId": loggedInInsurer?.id,
                "targetUserId": selectedUser.id
            };
            axios
                .post(`${process.env.REACT_APP_SERVER_API_URL}/transactions/topUp`, body,{headers: headers})
                .then((res) => {
                    closeTopUpModal();
                    toast.success(`We have successfully added £` + amount + ` to the user's health budget.`, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });
        }
    }




    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid #D9D9D959',
        borderRadius: '10px',
        p: 4,
    };

    const formikTopUp = useFormik({
        initialValues: {
            topUpAmount: 0
        },
        onSubmit: (values) => {
            topUpBudgetForUser(values.topUpAmount);
        },
        validationSchema: Yup.object({
            topUpAmount: Yup.number().typeError("Please enter a valid number").moreThan(0, 'Please enter an amount bigger than 0').required("Please enter an amount.")
        }),
    });

    const expandTopUpModal = (user: UserForInsurer, index: number) => {
        setTopUpModalIsOpen(true);
        setSelectedUser(user);
        setSelectedUserIndex(index);
    }

    const closeTopUpModal = () => {
        setTopUpModalIsOpen(false);
        setSelectedUser(undefined);
        setSelectedUserIndex(-1);
    }

    const startDeleteUserProcess = (userId: string) => {
        if (loggedInUser) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            axios.delete(
                `${process.env.REACT_APP_SERVER_API_URL}/users/` +
                userId +
                `/prepareAccountDeletion`,
                { headers: headers },
            )
                .then((res) => {
                    setUserToDelete(res.data);
                    setUserIdToDelete(userId);
                    setDeleteAccountDialogOpen(true);
                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }                });
        }
    };

    const removeUser = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        axios.delete(
            `${process.env.REACT_APP_SERVER_API_URL}/insurers/` +
            loggedInInsurer?.id +
            `/users/` + userIdToDelete,
            { headers: headers },
        )
                .then((res) => {
                    setUserToDelete(undefined);
                    setDeleteAccountDialogOpen(false);
                    toast.success(`The user has been removed from your plan.`);
                })
                .catch((err) => {
                    setUserToDelete(undefined);
                    setDeleteAccountDialogOpen(false);
                    toast.error(
                        "There was a problem removing the user from your plan.",
                    );
                });

    };

    interface EnhancedTableProps {
        numSelected: number;
        onRequestSort: (event: React.MouseEvent<unknown>, property: keyof UserForInsurer) => void;
        order: Order;
        orderBy: string;
        rowCount: number;
    }

    function EnhancedTableHead(props: EnhancedTableProps) {
        const { order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler =
            (property: keyof UserForInsurer) => (event: React.MouseEvent<unknown>) => {
                onRequestSort(event, property);
            };

        return (
            <TableHead sx={{background:"#4860E6"}}>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            sx={{color:"white"}}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                sx = {
                                    {
                                        '&.MuiTableSortLabel-root': {
                                            color: 'white',
                                        },
                                        '&.MuiTableSortLabel-root:hover': {
                                            color: '#F5F5F5',
                                            fontWeight: 'bold'
                                        },
                                        '&.Mui-active': {
                                            color: 'white',
                                            fontWeight: 'bold'
                                        },
                                        '& .MuiTableSortLabel-icon': {
                                            color: 'white !important',
                                        },
                                    }
                                }

                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    return (
        <Box sx={{background:"#F5F5F5"}}>
            <Header />
            <NavigationTabs selectedTab={"0"} />
            <Box sx={{paddingLeft: {
                    xs:"16px",
                    sm:"50px"
                }, paddingRight: {
                    xs: "16px",
                    sm: "50px"
                }, width: "100%", height:"100%", display: "flex", flexDirection: "row"}} color="primary">

                <div className="bg-white rounded-lg border-grey shadow-md py-4 px-4 sm:py-8 sm:px-8 w-full">
                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-end", marginBottom:"1rem"}}>
                        <Button variant="contained" color="secondary" sx={{marginRight:"1rem"}}>
                            <CSVLink filename={"users_on_HealthKey.csv"} data={csvData}>Download table</CSVLink>
                        </Button>
                        <form>
                        <TextField
                            id="search-bar"
                            className="text"
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                            }}
                            variant="outlined"
                            placeholder="Search by name or email"
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderRadius: 30
                                    },
                                    '& input': {
                                        width:"200px",
                                        fontSize:'12px',
                                        fontColor:'#6C6363'
                                    },
                                    background: "#F0F0F0",
                                    borderRadius: 30,
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#6C6363',
                                    }
                                }
                            }}
                            InputProps={{
                                startAdornment:<IconButton type="submit" aria-label="search">
                                    <SearchOutlined style={{ fill: "#6C6363" }} />
                                </IconButton>
                            }}
                        />

                    </form>
                    </Box>
                    <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                />
                                <TableBody>
                                    {stableSort(rows, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((user, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, user.name)}
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={user.name}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="normal"
                                                    >
                                                        {user.name}
                                                    </TableCell>
                                                    <TableCell align="right">{user.email}</TableCell>
                                                    <TableCell align="right">{user.insurancePolicyNumber}</TableCell>
                                                    <TableCell align="right">{user.balance}</TableCell>
                                                    <TableCell align="right">{user.insurerTopUp}</TableCell>
                                                    <TableCell style={{ background: 'white'  }}>
                                                        <Box sx={{display:"flex", flexDirection:"row"}}>
                                                            <IconButton onClick={() => expandTopUpModal(user, index)} color="primary">
                                                                <Tooltip id="button-report" title="Top-up user">
                                                                    <SavingsOutlined fontSize="small"/>
                                                                </Tooltip>
                                                            </IconButton>
                                                            <IconButton onClick={() =>
                                                                startDeleteUserProcess(user.id)
                                                            } color="primary">
                                                                <Tooltip id="button-report" title="Top-up user">
                                                                    <DeleteOutlined fontSize="small"/>
                                                                </Tooltip>
                                                            </IconButton>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (dense ? 33 : 53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                </div>
            </Box>


            <Modal
                open={topUpModalIsOpen}
                onClose={closeTopUpModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}>
                <Box sx={modalStyle}>
                        <Typography color="text.secondary">
                            Please select the amount you want to top-up.
                        </Typography>
                        <form onSubmit={formikTopUp.handleSubmit}>
                            <TextField
                                id="topUpAmount"
                                name="topUpAmount"
                                label="Amount"
                                variant="standard"
                                margin="normal"
                                fullWidth
                                sx={visibleTextfield}
                                value={formikTopUp.values.topUpAmount}
                                onChange={formikTopUp.handleChange}
                                error={formikTopUp.touched.topUpAmount && Boolean(formikTopUp.errors.topUpAmount)}
                                helperText={formikTopUp.touched.topUpAmount && formikTopUp.errors.topUpAmount}
                                InputProps={{
                                    startAdornment: <span style={{paddingRight: "4px"}}>{'£'}</span>
                                }}
                            />
                            <Box sx={{marginTop: "16px;"}}>
                                <Button color="secondary" variant="contained" type="submit">
                                    Top up
                                </Button>
                            </Box>
                        </form>
                </Box>
            </Modal>
            <Dialog
                open={deleteAccountDialogOpen}
                onClose={handleDeleteAccountDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    color="secondary"
                    fontWeight="bold"
                    sx={{ padding: "2.5rem 2.5rem 1rem 2.5rem" }}
                >
                    {
                        "Please confirm that you want to remove this user from your plan."
                    }
                </DialogTitle>
                <DialogContent sx={{ padding: "2.5rem 2.5rem 2.5rem 2.5rem" }}>
                    <Typography
                        fontWeight="bold"
                        sx={{ width: "100%" }}
                        mt={2}
                        variant="body1"
                    >
                        If you remove their account, they won&apos;t be be part of your
                        HealthKey plan anymore.
                    </Typography>

                    {
                    userToDelete?.amountLeftFromInsurer ? (
                        <div>
                            <Typography
                                sx={{ width: "100%" }}
                                mt={2}
                                mb={2}
                                variant="body1"
                            >
                                They still have money from you, left in their health budget.
                                Once the user is removed from your plan, they
                                won&apos;t be able to use this budget any longer.
                            </Typography>
                            <List sx={{ listStyleType: "disc", paddingLeft: "40px" }}>
                                {userToDelete?.amountLeftFromInsurer ? (
                                    <ListItem sx={{ display: "list-item" }}>
                                        <b>£{userToDelete.amountLeftFromInsurer} </b>
                                    </ListItem>
                                ) : (
                                    <div />
                                )}
                            </List>
                        </div>
                    ) : (
                        <div />
                    )}
                </DialogContent>
                <DialogActions sx={{ padding: "0rem 2.5rem 2.5rem 2.5rem" }}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => handleDeleteAccountDialogClose()}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="error"
                        variant="contained"
                        onClick={() => removeUser()}
                    >
                        Remove employee
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Footer displayLogo={true} selectedTab={"0"}/>
        </Box>
    );
};

export default Home;