import Header from "../components/Header";
import NavigationTabs from "../components/NavigationTabs";
import {
    Backdrop,
    Box, Button, Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField, Typography,
} from "@mui/material";
import {toast, ToastContainer} from "react-toastify";
import {useAppSelector} from "../store/hooks";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {HealthCategory, Product} from "../types/types";
import axios, {all} from "axios";
import authSlice from "../store/slices/auth";


const Services = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const accessToken = useAppSelector(state => state.auth.token);
    const loggedInInsurer = useAppSelector(state => state.insurer.loggedInInsurer);

    const [allServices, setAllServices] = useState<Product[]>(new Array<Product>());
    const [editServiceModalIsOpen, setEditServiceModalIsOpen] = useState(false);
    const [selectedService, setSelectedService] = useState<Product | undefined>(undefined);
    const [serviceHealthCategories, setServiceHealthCategories] = useState<Array<HealthCategory>>(new Array());
    const [servicePrice, setServicePrice] = useState("0");
    const [serviceName, setServiceName] = useState("");
    const [selectedServiceCategories, setSelectedServiceCategories] = useState<Array<HealthCategory>>(new Array());
    const [errorMessage, setErrorMessage] = useState("");
    const [productsForInsurer, setProductsForInsurer] = useState<Product[]>(new Array<Product>());
    useEffect( () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        readServicesForInsurer();
        axios
            .get(`${process.env.REACT_APP_SERVER_API_URL}/products`, {headers: headers}).then((res) => {
            setAllServices(res.data);
        })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
        axios
            .get(`${process.env.REACT_APP_SERVER_API_URL}/healthCategories`, {headers: headers}).then((res) => {
                setServiceHealthCategories(res.data);
            })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
    }, [])

    const readServicesForInsurer = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }
        axios
            .get(`${process.env.REACT_APP_SERVER_API_URL}/insurers/`+ loggedInInsurer?.id+ `/products`, {headers: headers}).then((res) => {
            setProductsForInsurer(res.data);
        })
            .catch((err) => {
                if (err.response!.status === 401 || err.response!.status === 403) {
                    dispatch(authSlice.actions.logout());
                    navigate("/sessionExpired");
                }
            });
    }

    const expandEditServiceModal = (service: Product) => {
        setEditServiceModalIsOpen(true);
        setSelectedService(service);
        setServicePrice(service.price + "");
        setServiceName(service.name);
        let categories = new Array();
        if (service.categories) {
            for (let categoryId of service.categories) {
                categories.push(getHealthCategoryById(categoryId));
            }
        }
        setSelectedServiceCategories(categories);
    }

    const getHealthCategoryById = (id: string) : HealthCategory | undefined => {
        for (let category of serviceHealthCategories) {
            if (category.id === id) {
                return category;
            }
        }
        return undefined;
    }

    const closeEditServiceModal = () => {
        setEditServiceModalIsOpen(false);
        setServicePrice("0");
        setServiceName("");
        setErrorMessage("");
    }

    const isProductIncluded = (productId : string) : boolean => {
        if (productsForInsurer) {
            for (let product of productsForInsurer) {
                if (product.id === productId) {
                    return true;
                }
            }
        }
        return false;
    }

    const removeProductFromPlan = (service: Product) => {
        if (service && loggedInInsurer) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            axios
                .delete(`${process.env.REACT_APP_SERVER_API_URL}/insurers/` + loggedInInsurer.id + `/products/` + service.id, {headers: headers}).then((res) => {
                readServicesForInsurer();

                toast.success('Your details have been successfully updated.');
                closeEditServiceModal();

            })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });
        }
    }

    const updateServiceChanges = () => {
        if (selectedService && loggedInInsurer) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            axios
                .put(`${process.env.REACT_APP_SERVER_API_URL}/insurers/` + loggedInInsurer.id + `/products/` + selectedService.id, {price: servicePrice},{headers: headers}).then((res) => {
                readServicesForInsurer();

                toast.success('Your details have been successfully updated.');
                    closeEditServiceModal();

            })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                });
        }
    }

    const largeModalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        border: '1px solid #D9D9D959',
        borderRadius: '10px',
        p: 4,
    };

    const visibleTextfield = {
        "& label": {
            color: '#6C6363'
        },
        '& label.Mui-focused': {
            color: '#6C6363',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6C6363',
        },
        "& .MuiInputBase-root": {
            color: '#6C6363'
        }
    };

    return (
        <Box sx={{background:"#F5F5F5"}}>
            <Header />
            <NavigationTabs selectedTab={"3"} />
            <Box sx={{paddingLeft: {
                    xs:"16px",
                    sm:"50px"
                }, paddingRight: {
                    xs: "16px",
                    sm: "50px"
                }, width: "100%", height:"100%", display: "flex", flexDirection: "row"}} color="primary">

                <div className="bg-white rounded-lg border-grey shadow-md py-4 px-4 sm:py-8 sm:px-8 w-full">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Service name</TableCell>
                                    <TableCell align="right">Included</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Categories</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productsForInsurer && allServices && allServices.map((product) => (
                                    <TableRow
                                        key={product.externalId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {product.name}
                                        </TableCell>
                                        <TableCell align="right">
                                            <div className="w-[160px]">
                                            {isProductIncluded(product.id) ? <Box sx={{borderRadius: "10px", background:"#E9F5E3", color:"#242426", padding:"5px 10px 5px 10px", margin:{xs:"8px 0px 8px 0px", sm:"8px 0px 8px 16px"}}}>
                                                <Typography variant="body2" align="center" color="#27104E" >
                                                    Included
                                                </Typography>
                                            </Box> : <Box sx={{borderRadius: "10px", background:"#FFDDD5", color:"#242426", padding:"5px 10px 5px 10px", margin:{xs:"8px 0px 8px 0px", sm:"8px 0px 8px 16px"}}}>
                                                <Typography variant="body2" align="center" color="#27104E" >
                                                    Not included
                                                </Typography>
                                            </Box>}
                                            </div>
                                        </TableCell>
                                        <TableCell align="right">£{product.price}</TableCell>
                                        <TableCell>
                                            <div className="flex flex-row-reverse flex-wrap">
                                                {product.categories.map((category) => (
                                                    <div key={category}>
                                                        <Box sx={{borderRadius: "10px", background:"#E7F0FE", color:"#242426", padding:"5px 10px 5px 10px", margin:{xs:"8px 0px 8px 0px", sm:"8px 0px 8px 16px"}}}>
                                                            <Typography variant="body2" align="center" color="#27104E" >
                                                                {getHealthCategoryById(category)?.name}
                                                            </Typography>
                                                        </Box>
                                                    </div>
                                                ))}
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Button variant="contained" onClick={() => {expandEditServiceModal(product);}}>
                                                Include
                                            </Button>
                                        </TableCell>
                                        {isProductIncluded(product.id) && <TableCell align="left">
                                            <Button variant="contained" onClick={() => {removeProductFromPlan(product);}}>
                                                Remove
                                            </Button>
                                        </TableCell>}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Box>
            <Modal
                open={editServiceModalIsOpen}
                onClose={closeEditServiceModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}>
                <Box sx={largeModalStyle}>
                    <div className="flex flex-row mb-8">
                        <div className="w-4/5 mr-8">
                            <TextField
                                id="serviceName"
                                name="serviceName"
                                label="Name"
                                variant="standard"
                                margin="normal"
                                type="text"
                                fullWidth
                                sx={visibleTextfield}
                                value={serviceName}
                                onChange={e => setServiceName(e.target.value)}
                            />
                        </div>
                        <div className="w-1/5">
                            <TextField
                                id="servicePrice"
                                name="servicePrice"
                                label="Price"
                                variant="standard"
                                margin="normal"
                                type="number"
                                fullWidth
                                sx={visibleTextfield}
                                value={servicePrice}
                                onChange={e => setServicePrice(e.target.value)}
                                InputProps={{
                                    startAdornment: <span style={{paddingRight: "4px"}}>{'£'}</span>
                                }}
                            />
                        </div>
                    </div>
                    <Box sx={{marginTop: "16px;"}}>
                        <Button color="secondary" variant="contained" type="submit" onClick={() => updateServiceChanges()}>
                            Include service
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeButton={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={1}
                theme="colored"
            />
        </Box>
    )
}

export default Services;