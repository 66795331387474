import {
    Alert,
    Box,
    Button,
    TextField,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../store/hooks";
import userDetails from "../store/slices/userDetails";
import Header from "../components/Header";
import authSlice from "../store/slices/auth";
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import Footer from "../components/Footer";

const MyAccount = () => {

    const [message, setMessage] = useState("");
    const [passwordChangeMessage, setPasswordChangeMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loggedInUser = useAppSelector(state => state.userDetails.user);
    const accessToken = useAppSelector(state => state.auth.token);


    const handleUpdateDetails = (email?: string, name?: string, first_name?: string, last_name?: string, gender?: string, address?: string) => {
        if (loggedInUser) {
            const body = {email, name, first_name, last_name, gender, address};
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            axios
                .put(`${process.env.REACT_APP_API_URL}/updateUser/` + loggedInUser.id, body, {headers: headers})
                .then((res) => {
                    dispatch(userDetails.actions.setUser(res.data));
                    setLoading(false);
                    toast.success('Your details have been successfully updated.', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                    if (err.response.data.email) {
                        toast.error(err.response.data.email.email, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }
                    setLoading(false);
                });
        }

    };

    const handleChangePassword = (old_password?: string, password?: string, password2?: string) => {
        if (loggedInUser) {
            const body = {old_password, password, password2};
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            axios
                .put(`${process.env.REACT_APP_API_URL}/changePassword/` + loggedInUser.id, body, {headers: headers})
                .then((res) => {
                    setLoading(false);
                    toast.success('Your password has been successfully changed.', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        dispatch(authSlice.actions.logout());
                        navigate("/sessionExpired");
                    }
                    if (err.response.data.old_password) {
                        toast.error("The old password doesn't seem to be correct.", {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    } if (err.response.data.password) {
                        toast.error(err.response.data.password[0], {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    }
                    setLoading(false);
                });
        }

    };



    const formikUserDetails = useFormik({
        initialValues: {
            email: loggedInUser?.email,
            firstName: loggedInUser?.first_name,
            lastName: loggedInUser?.last_name
        },

        onSubmit: (values) => {
            setLoading(true);
            handleUpdateDetails(values.email, values.firstName + " " + values.lastName, values.firstName, values.lastName);
        },
        validationSchema: Yup.object({
            email: Yup.string().trim().email('Invalid email address').required("The email is required."),
            firstName: Yup.string().trim().max(15, 'Must be 15 characters or less').required("The first name is required."),
            lastName: Yup.string().trim().max(15, 'Must be 15 characters or less').required("The last name is required."),
        }),

    });

    const formikChangePassword = useFormik({
        initialValues: {
            oldPassword: "",
            password: "",
            password2: ""
        },

        onSubmit: (values) => {
            handleChangePassword(values.oldPassword, values.password, values.password2);
        },
        validationSchema: Yup.object({
            password: Yup.string().trim().required("The password is required")
                .min(8, "Your password must be longer than 8 characters.")
                .max(50, "Your password mustn't be longer than 50 characters.")
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])/,
                    "Your password must contain at least one uppercase and one lowercase character."
                )
                .matches(/^(?=.{6,20}$)\D*\d/, "Your password must contain at least one digit."),
            password2: Yup.string()
                .test('passwords-match', 'Passwords must match', function (value) {
                    return this.parent.password === value
                })
        }),
    });

    const visibleTextfield = {
        "& label": {
            color: '#6C6363'
        },
        '& label.Mui-focused': {
            color: '#6C6363',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6C6363',
        },
        "& .MuiInputBase-root": {
            color: '#6C6363'
        }
    };

    const visibleTextfieldWithPaddingRight = {
        "& label": {
            color: '#6C6363'
        },
        '& label.Mui-focused': {
            color: '#6C6363',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6C6363',
        },
        "& .MuiInputBase-root": {
            color: '#6C6363'
        },
        paddingRight: {
            xs: "0px",
            sm: '40px'
        }
    };

    return (
        <>
            <Header/>
            <Box sx={{paddingLeft:{sm: "200px"}, paddingRight: {sm:"200px"}, paddingTop: "100px", background:"#F5F5F5", height:"100%"}}>

                <Box sx={{width: "100%", display: "flex", flexDirection: "column", height:"100vh"}}>
                    <div className="mx-8 sm:mx-0 bg-white rounded-lg border-grey shadow-md">

                        <Box sx={{display: "flex", flexDirection: "column", padding:{
                                xs:"1rem 2rem 1rem 2rem",
                                sm:"2rem 4rem 2rem 4rem"
                            }, marginTop:"1rem"}}>
                        <form onSubmit={formikUserDetails.handleSubmit}>
                            <Typography fontWeight="bold" mb={2} color="secondary" variant="h5">Profile</Typography>
                            <Box sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: {xs:"column",sm:"row"},
                                justifyContent: "space-between"
                            }}>
                                <TextField
                                    id="firstName"
                                    name="firstName"
                                    label="First name"
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    required
                                    sx={visibleTextfieldWithPaddingRight}
                                    value={formikUserDetails.values.firstName}
                                    onChange={formikUserDetails.handleChange}
                                    onBlur={formikUserDetails.handleBlur}
                                    error={formikUserDetails.touched.firstName && Boolean(formikUserDetails.errors.firstName)}
                                    helperText={formikUserDetails.touched.firstName && formikUserDetails.errors.firstName}
                                />
                                <TextField
                                    id="lastName"
                                    name="lastName"
                                    label="Last name"
                                    variant="standard"
                                    margin="normal"
                                    sx={visibleTextfieldWithPaddingRight}
                                    fullWidth
                                    required
                                    value={formikUserDetails.values.lastName}
                                    onChange={formikUserDetails.handleChange}
                                    error={formikUserDetails.touched.lastName && Boolean(formikUserDetails.errors.lastName)}
                                    helperText={formikUserDetails.touched.lastName && formikUserDetails.errors.lastName}
                                />
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Work email"
                                    variant="standard"
                                    margin="normal"
                                    sx={visibleTextfield}
                                    required
                                    fullWidth
                                    value={formikUserDetails.values.email}
                                    onChange={formikUserDetails.handleChange}
                                    onBlur={formikUserDetails.handleBlur}
                                    error={formikUserDetails.touched.email && Boolean(formikUserDetails.errors.email)}
                                    helperText={formikUserDetails.touched.email && formikUserDetails.errors.email}
                                />
                            </Box>
                            {message ? <Alert severity="error">{message}</Alert> : <></>}
                            <Box sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "space-between"
                            }}>
                                <div className="flex justify-center items-center mt-6">
                                    <Button color="secondary" variant="contained" type="submit" disabled={loading}>
                                        Save details
                                    </Button>
                                </div>
                            </Box>
                        </form>
                    </Box>

                    </div>
                    <div className="mx-8 sm:mx-0 bg-white rounded-lg border-grey shadow-md sm:mt-12 mt-8">

                        <Box sx={{display: "flex", flexDirection: "column", padding:{
                            xs:"1rem 2rem 1rem 2rem",
                            sm:"2rem 4rem 2rem 4rem"
                        }, marginTop:"1rem"}}>
                        <form onSubmit={formikChangePassword.handleSubmit}>
                            <Typography fontWeight="bold" mb={2} color="secondary" variant="h5">Authentication
                                details</Typography>
                            <Box sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: {xs:"column",sm:"row"},
                                justifyContent: "space-between"
                            }}>
                                <TextField
                                    id="oldPassword"
                                    name="oldPassword"
                                    label="Old password"
                                    variant="standard"
                                    margin="normal"
                                    type="password"
                                    sx={visibleTextfieldWithPaddingRight}
                                    fullWidth
                                    value={formikChangePassword.values.oldPassword}
                                    onChange={formikChangePassword.handleChange}
                                    error={formikChangePassword.touched.oldPassword && Boolean(formikChangePassword.errors.oldPassword)}
                                    helperText={formikChangePassword.touched.oldPassword && formikChangePassword.errors.oldPassword}
                                />
                                <TextField
                                    id="password"
                                    name="password"
                                    label="New password"
                                    type="password"
                                    margin="normal"
                                    sx={visibleTextfieldWithPaddingRight}
                                    variant="standard"
                                    fullWidth
                                    value={formikChangePassword.values.password}
                                    onChange={formikChangePassword.handleChange}
                                    onBlur={formikChangePassword.handleBlur}
                                    error={formikChangePassword.touched.password && Boolean(formikChangePassword.errors.password)}
                                    helperText={formikChangePassword.touched.password && formikChangePassword.errors.password}
                                />
                                <TextField
                                    id="password2"
                                    name="password2"
                                    label="Repeat new password"
                                    type="password"
                                    margin="normal"
                                    sx={visibleTextfield}
                                    fullWidth
                                    variant="standard"
                                    value={formikChangePassword.values.password2}
                                    onChange={formikChangePassword.handleChange}
                                    onBlur={formikChangePassword.handleBlur}
                                    error={formikChangePassword.touched.password2 && Boolean(formikChangePassword.errors.password2)}
                                    helperText={formikChangePassword.touched.password2 && formikChangePassword.errors.password2}
                                />
                            </Box>
                            {passwordChangeMessage ? <Alert severity="error">{passwordChangeMessage}</Alert> : <></>}
                            <Box sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "space-between"
                            }}>
                                <div className="flex justify-center items-center mt-6">
                                    <Button color="secondary" variant="contained" type="submit" disabled={loading}>
                                        Change password
                                    </Button>
                                </div>
                            </Box>
                        </form>
                    </Box>
                    </div>
                </Box>



                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <Footer displayLogo={true} selectedTab={"5"}/>
            </Box>
        </>

    );
};

export default MyAccount;