import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {Insurer, User, UserForInsurer} from "../../types/types";

type InsurerState = {
    loggedInInsurer: Insurer | null;

    users: UserForInsurer[] | null;
};

const initialState: InsurerState = { loggedInInsurer: null, users: new Array<UserForInsurer>()};

const insurerSlice = createSlice({
    name: "insurer",
    initialState,
    reducers: {
        setLoggedInInsurer(state: InsurerState, action: PayloadAction<Insurer>) {
            state.loggedInInsurer = action.payload;
        },
        setUsers (state: InsurerState, action: PayloadAction<UserForInsurer[]>) {
            state.users = action.payload;
        }
    },
});

export default insurerSlice;