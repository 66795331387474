import React from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import authSlice from "../store/slices/auth";
import {
    Button,
    AppBar,
    Box,
    Toolbar,
    Typography,
    Link,
    Tooltip,
    IconButton,
    MenuItem,
    Avatar,
    ListItemIcon,
    Menu
} from "@mui/material";
import logo from "../images/Logo (Dark background).png";
import userDetailsSlice from "../store/slices/userDetails";
import {
    AccountCircleOutlined,
    HelpOutline,
    Logout,
    KeyboardArrowDownOutlined, HealthAndSafetyOutlined, HomeOutlined, ContactPageOutlined, SupportOutlined
} from "@mui/icons-material";
import employerSlice from "../store/slices/insurer";


const Header = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(authSlice.actions.logout());
        dispatch(userDetailsSlice.actions.logout());

        navigate("/login");
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [anchorHelpEl, setAnchorHelpEl] = React.useState<null | HTMLElement>(null);
    const openHelp = Boolean(anchorHelpEl);

    const handleClickSettings = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseSettings = () => {
        setAnchorEl(null);
    };
    const handleClickHelp = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorHelpEl(event.currentTarget);
    };

    const handleCloseHelp = () => {
        setAnchorHelpEl(null);
    };


    return (
        <header>
            <AppBar color="primary">
                <Toolbar>
                    <Box sx={{ flex: 1 }}>
                        <Link href="/">
                            <Box sx={{display:"flex", flex:"row"}}>
                            <Box
                                component="img"
                                alt="Logo"
                                src={logo}
                                sx={{
                                    paddingLeft:{sm:"76px"}, marginRight:"10px", height:"45px"}}
                            />
                            </Box>
                        </Link>
                    </Box>
                    <Typography sx={{marginRight:"20px"}} variant="h6" color="white">Insurer portal</Typography>
                    <Box className="hidden sm:block">
                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleClickSettings}
                                size="medium"
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <AccountCircleOutlined sx={{color:"#fff"}}  fontSize="medium" />
                                <KeyboardArrowDownOutlined sx={{color:"#fff"}}  fontSize="medium" />

                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleCloseSettings}
                            onClick={handleCloseSettings}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem sx={{color:"#27104E"}}   onClick={() => navigate("/myAccount")}>
                                <ListItemIcon>
                                    <AccountCircleOutlined sx={{color:"#27104E"}}   fontSize="small" />
                                </ListItemIcon>
                                My account
                            </MenuItem>
                            {/*<MenuItem sx={{color:"#27104E"}}   onClick={() => navigate("/myHealthData")}>*/}
                            {/*    <ListItemIcon>*/}
                            {/*        <HealthAndSafetyOutlined sx={{color:"#27104E"}}   fontSize="small" />*/}
                            {/*    </ListItemIcon>*/}
                            {/*    My health data*/}
                            {/*</MenuItem>*/}
                            <MenuItem sx={{color:"#27104E"}}  onClick={() => navigate("/")}>
                                <ListItemIcon>
                                    <HomeOutlined sx={{color:"#27104E"}}  fontSize="small" />
                                </ListItemIcon>
                                Home
                            </MenuItem>
                            <MenuItem sx={{color:"#27104E"}}  onClick={handleLogout}>
                                <ListItemIcon>
                                    <Logout sx={{color:"#27104E"}}  fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
        </header>
    );
}

export default Header;