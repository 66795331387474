import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    Employer,
    Provider,
} from "../../types/types";

type EmployerState = {
    loggedInEmployer: Employer | null;

    selectedProviders: Provider[];

};

const initialState: EmployerState = {
    loggedInEmployer: null,
    selectedProviders: new Array<Provider>()
};

const employerSlice = createSlice({
    name: "employer",
    initialState,
    reducers: {
        setLoggedInEmployer(
            state: EmployerState,
            action: PayloadAction<Employer>,
        ) {
            state.loggedInEmployer = action.payload;
        },

        setSelectedProviders(
            state: EmployerState,
            action: PayloadAction<Provider[]>,
        ) {
            state.selectedProviders = action.payload;
        },
    },
});

export default employerSlice;
