import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import axios from "axios";
import authSlice from "../store/slices/auth";
import userDetailsSlice from "../store/slices/userDetails";
import {Alert, Box, Button, TextField, Typography} from "@mui/material";
import {useAppDispatch} from "../store/hooks";
import logoWithText from "../images/Logo (Dark background).png"
import OutsideLeftComponent from "../components/OutsideLeftComponent";

function Login() {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useAppDispatch();
    let navigate = useNavigate();

    const response_type = searchParams.get('response_type')
    const client_id = searchParams.get('client_id')
    const redirect_uri = searchParams.get('redirect_uri')
    const scope = searchParams.get('scope')
    const code_challenge = searchParams.get('code_challenge')
    const code_challenge_method = searchParams.get('code_challenge_method')

    const visibleTextfield = {
        "& label": {
            color: '#6C6363'
        },
        '& label.Mui-focused': {
            color: '#6C6363',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#6C6363',
        },
        "& .MuiInputBase-root": {
            color: '#6C6363'
        }
    };

    const handleLogin = (email: string, password: string) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/login/`, { email, password })
            .then((res) => {
                if (res.data.user.is_employer_admin) {
                    dispatch(
                        authSlice.actions.setAuthTokens({
                            token: res.data.access,
                            refreshToken: res.data.refresh,
                        })
                    );
                    dispatch(authSlice.actions.setAccount(res.data.user));
                    dispatch(userDetailsSlice.actions.setUser(res.data.user));
                    setLoading(false);
                    navigate("/");
                } else {
                    setMessage("You are not authorised to access this portal.");
                    setLoading(false);
                }

            })
            .catch((err) => {
                setMessage("Email/password combination doesn't seem to be recognised.");
                setLoading(false);
            });
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: (values) => {
            setLoading(true);
            handleLogin(values.email, values.password);
        },
        validationSchema: Yup.object({
            email: Yup.string().trim().required("The email is required."),
            password: Yup.string().trim().required("The password is required"),
        }),
    });

    return (
        <Box className="sm:h-screen flex sm:flex-row flex-col bg-white"
             sx={{backgroundImage:
                         {sm: "linear-gradient(180.1deg, #4860E6 55.01%, rgba(217, 217, 217, 0.35) 99.91%)"}}}>
            <OutsideLeftComponent/>
            <div className="block sm:hidden bg-purple w-full py-2 mr-2">
                <Box
                    component="img"
                    alt="Logo"
                    src={logoWithText}
                    sx={{width: "190px", height: "45px", marginLeft:"1.25rem"}}
                />
            </div>
            <div className="sm:w-2/3 h-full m-auto bg-white sm:rounded-l-3xl border border-white py-5 px-5 w-full sm:py-36 sm:pl-44 sm:pr-96">
                <div className="mb-4">
                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                        Login to your insurer admin account
                    </Typography>

                </div>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        variant="standard"
                        margin="normal"
                        fullWidth
                        sx={visibleTextfield}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        fullWidth
                        sx={visibleTextfield}
                        variant="standard"
                        margin="normal"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                    {message ? <Alert severity="error">{message}</Alert> : <></>}
                    <Typography align="right" variant="body2" mt={2}>
                        <Link className="underline" to={"/forgottenPasswordEmail"}>Forgotten password?</Link>
                    </Typography>
                    <div className="mt-4">
                        <Button color="secondary" className="w-full sm:w-72" sx={{borderRadius: "9px"}}
                                variant="contained" type="submit" disabled={loading}>
                            Login
                        </Button>
                    </div>
                </form>
            </div>
        </Box>
    );
}

export default Login;