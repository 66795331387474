import Header from "../components/Header";
import NavigationTabs from "../components/NavigationTabs";
import React, {useEffect, useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import {useAppSelector} from "../store/hooks";
import axios from "axios";
import { CSVLink, CSVDownload } from 'react-csv';

import {useNavigate} from "react-router-dom";
import {InsurerMonthlyBudgetInformation} from "../types/types";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const InsurerBudget = () => {
    const navigate = useNavigate();

    const loggedInUser = useAppSelector((state) => state.userDetails.user);
    const accessToken = useAppSelector(state => state.auth.token);
    const loggedInInsurer = useAppSelector(state => state.insurer.loggedInInsurer);




    const [spendingGraph, setSpendingGraph] = useState(new Array<any>());
    const [topUpGraph, setTopUpGraph] = useState(new Array<any>());

    const [isSpendingLoading, setIsSpendingLoading] = useState(true);
    const [totalSpend, setTotalSpend] = useState(0);
    const [totalBudgetSpend, setTotalBudgetSpend] = useState(0);
    const [totalInsurerBudgetSpend, setTotalInsurerBudgetSpend] = useState(0);

    const [totalSacrificeSpend, setTotalSacrificeSpend] = useState(0);
    const [totalUserSpend, setTotalUserSpend] = useState(0);
    const [totalGiftSpend, setTotalGiftSpend] = useState(0);
    const [totalBudgetLastSpend, setTotalBudgetLastSpend] = useState(0);
    const [totalBudgetThisSpend, setTotalBudgetThisSpend] = useState(0);
    const [totalInsurerLastSpend, setTotalInsurerLastSpend] = useState(0);
    const [totalInsurerThisSpend, setTotalInsurerThisSpend] = useState(0);
    const [budgetAllocationReport, setBudgetAllocationReport] = useState(
        new Array<any>(),
    );

    const objToStrMap = (obj: { [x: string]: any }) => {
        let strMap = new Map();
        for (let k of Object.keys(obj)) {
            strMap.set(k, obj[k]);
        }
        return strMap;
    };

    useEffect(() => {
        if (loggedInUser && loggedInInsurer) {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
            axios
                .get(`${process.env.REACT_APP_SERVER_API_URL}/insurers/` + loggedInInsurer.id + '/budgetInformation', {headers: headers})
                .then((res) => {
                    let spendingGraph = new Array<any>();
                    let topUpGraph = new Array<any>();
                    let allocationReport = new Array<any>();
                    const spendingMap = objToStrMap(res.data);
                    let totalBudgetSpend = 0;
                    let totalInsurerBudgetSpend = 0;
                    let totalSpend = 0;
                    let totalSacrificeSpend = 0;
                    let totalUserSpend = 0;
                    let totalGiftSpend = 0;
                    let totalBudgetLastSpend = 0;
                    let totalBudgetThisSpend = 0;
                    let totalInsurerBudgetThisSpend = 0;
                    let totalInsurerBudgetLastSpend = 0;
                    let topUpSoFar = 0;
                    let spendSoFar = 0;

                    const today = new Date();
                    const thisMonth = (today.getUTCMonth() + 1).toString().padStart(2, "0");
                    const year = today.getUTCFullYear();
                    today.setDate(0);
                    const lastMonth = (today.getUTCMonth() + 1).toString().padStart(2, "0");
                    const lastMonthYear = today.getUTCFullYear();

                    spendingMap.forEach(
                        (entry: InsurerMonthlyBudgetInformation, key: string) => {
                            topUpSoFar =
                                topUpSoFar + entry.insurerBudgetAllocated;
                            spendSoFar = spendSoFar + entry.insurerBudgetSpend;
                            spendingGraph.push({
                                key: key,
                                topUp: topUpSoFar,
                                totalSpend: entry.totalSpend,
                                budgetSpend: entry.budgetSpend,
                                insurerSpend: entry.insurerBudgetSpend,
                                userSpend: entry.userSpend,
                                giftSpend: entry.giftSpend,
                                salarySacrificeSpend: entry.salarySacrificeSpend,
                            });
                            allocationReport.push({
                                month: key,
                                allocatedBudget: entry.budgetAllocated,
                                insurerBudgetAllocated: entry.insurerBudgetAllocated,
                                totalSpend: entry.totalSpend,
                                budgetSpend: entry.budgetSpend,
                                userSpend: entry.userSpend,
                                insurerSpend: entry.insurerBudgetSpend,
                                giftSpend: entry.giftSpend,
                                salarySacrificeSpend: entry.salarySacrificeSpend,
                            });
                            totalSpend = totalSpend + entry.totalSpend;
                            totalBudgetSpend = totalBudgetSpend + entry.budgetSpend;
                            totalInsurerBudgetSpend = totalInsurerBudgetSpend + entry.insurerBudgetSpend;
                            totalSacrificeSpend =
                                totalSacrificeSpend + entry.salarySacrificeSpend;
                            totalUserSpend = totalUserSpend + entry.userSpend;
                            totalGiftSpend = totalGiftSpend + entry.giftSpend;
                            if (key === year + "-" + thisMonth) {
                                totalBudgetThisSpend = totalBudgetThisSpend + entry.budgetSpend;
                                totalInsurerBudgetThisSpend =
                                    totalInsurerBudgetThisSpend + entry.insurerBudgetSpend;
                            }
                            if (key === lastMonthYear + "-" + lastMonth) {
                                totalBudgetLastSpend = totalBudgetLastSpend + entry.budgetSpend;
                                totalInsurerBudgetLastSpend =
                                    totalInsurerBudgetLastSpend + entry.insurerBudgetSpend;
                            }
                            topUpGraph.push({
                                key: key,
                                topUp: topUpSoFar,
                                spend: spendSoFar,
                            });
                        },
                    );
                    setSpendingGraph(spendingGraph);
                    setTopUpGraph(topUpGraph);
                    setTotalBudgetSpend(totalBudgetSpend);
                    setTotalSpend(totalSpend);
                    setTotalSacrificeSpend(totalSacrificeSpend);
                    setTotalUserSpend(totalUserSpend);
                    setTotalGiftSpend(totalGiftSpend);
                    setTotalBudgetThisSpend(totalBudgetThisSpend);
                    setTotalBudgetLastSpend(totalBudgetLastSpend);
                    setTotalInsurerThisSpend(totalInsurerBudgetThisSpend);
                    setTotalInsurerLastSpend(totalInsurerBudgetLastSpend);
                    setTotalInsurerBudgetSpend(totalInsurerBudgetSpend);
                    setIsSpendingLoading(false);
                    setBudgetAllocationReport(allocationReport);                })
                .catch((err) => {
                    if (err.response!.status === 401 || err.response!.status === 403) {
                        navigate("/sessionExpired");
                    }
                });

        }
    }, [loggedInInsurer, loggedInUser]);


    return (
        <Box sx={{background:"#F5F5F5"}}>
            <Header />
            <NavigationTabs selectedTab={"1"} />
            <Box sx={{paddingLeft: {
                    xs:"16px",
                    sm:"50px"
                }, paddingRight: {
                    xs: "16px",
                    sm: "50px"
                }, width: "100%", height:"100%", display: "flex", flexDirection: "row"}} color="primary">

                <div className="bg-white rounded-lg border-grey shadow-md py-4 px-4 sm:py-8 sm:px-8 w-full">
                    <div className="flex flex-col">
                        <Typography >Budget information</Typography>
                        {!isSpendingLoading && (
                            <div className="flex flex-col w-full mt-[16px]">
                                <ResponsiveContainer aspect={4}>
                                    <AreaChart
                                        width={1000}
                                        height={400}
                                        data={spendingGraph}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="key" />
                                        <YAxis />
                                        <Tooltip />
                                        <Area
                                            type="monotone"
                                            dataKey="insurerSpend"
                                            stackId="1"
                                            stroke="#c78c06"
                                            fill="#c78c06"
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="budgetSpend"
                                            stackId="1"
                                            stroke="#8aa902"
                                            fill="#8aa902"
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="userSpend"
                                            stackId="1"
                                            stroke="#358510"
                                            fill="#358510"
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="giftSpend"
                                            stackId="1"
                                            stroke="#267c5a"
                                            fill="#267c5a"
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="salarySacrificeSpend"
                                            stackId="1"
                                            stroke="#087476"
                                            fill="#087476"
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        )}
                        {!isSpendingLoading && (
                            <div className="flex flex-row my-[16px]">
                                <div className="flex flex-col w-1/3 mr-[32px]">
                                    <div className="flex flex-row">
                                        <Typography>Total insurer budget spend</Typography>
                                        <div className="ml-auto">
                                            <Typography>£{totalInsurerBudgetSpend}</Typography>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <Typography>Total employer budget spend</Typography>
                                        <div className="ml-auto">
                                            <Typography>£{totalBudgetSpend}</Typography>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <Typography>Total salary sacrifice spend</Typography>
                                        <div className="ml-auto">
                                            <Typography>£{totalSacrificeSpend}</Typography>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <Typography>Total spend by users</Typography>
                                        <div className="ml-auto">
                                            <Typography>£{totalUserSpend}</Typography>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <Typography>Total gift spend</Typography>
                                        <div className="ml-auto">
                                            <Typography>£{totalGiftSpend}</Typography>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <Typography>Total spend</Typography>
                                        <div className="ml-auto">
                                            <Typography>£{totalSpend}</Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col w-1/3 mr-[32px]">
                                    <div className="flex flex-row">
                                        <Typography>Insurer budget spend this month</Typography>
                                        <div className="ml-auto">
                                            <Typography>£{totalInsurerThisSpend}</Typography>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <Typography>Insurer budget spend last month:</Typography>
                                        <div className="ml-auto">
                                            <Typography>£{totalInsurerLastSpend}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!isSpendingLoading && (
                            <div className="flex flex-col w-full mt-[16px]">
                                <ResponsiveContainer aspect={4}>
                                    <AreaChart
                                        width={1000}
                                        height={400}
                                        data={topUpGraph}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="key" />
                                        <YAxis />
                                        <Tooltip />
                                        <Area
                                            type="monotone"
                                            dataKey="topUp"
                                            stackId="1"
                                            stroke="#8aa902"
                                            fill="#8aa902"
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="spend"
                                            stackId="2"
                                            stroke="#358510"
                                            fill="#358510"
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        )}
                        <div className="w-full flex flex-row-reverse">
                            <Button variant="contained">
                                <CSVLink
                                    data={budgetAllocationReport}
                                    filename={"budget-allocation.csv"}
                                >
                                    Download Report
                                </CSVLink>
                            </Button>
                        </div>
                    </div>
                </div>
                </Box>
        </Box>
    );
};

export default InsurerBudget;